import SwiperWrapper from 'components/SwiperWrapper';
import Trusted from 'pages/RegistrationFunnel/components/Trusted';
import { useRef, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SwiperSlide } from 'swiper/react';
import { PlansType, TSelectedPlan } from "types/plans";
import NextPrevSlider from "./NextPrevSlider";
import Plan from "./Plan";
import PlansComparison from "./PlansComparison";



interface Props {
    plans: PlansType;
    plansWrapperClassName?: string;
    handleSelectPlan?: (newVal: TSelectedPlan) => void;
    handleProToolUpgrade?: () => void;
    fromRegistration?: boolean;
    selectedPlan?: "pro" | "pro-plus" | "enterprise";
    isPayNow?: boolean;
    comparisonContStyles?: string;
    hideTrustpilot?: boolean;
}


const SlidedPlansWithComparison = ({
    plans,
    fromRegistration = false,
    plansWrapperClassName,
    handleSelectPlan,
    handleProToolUpgrade,
    hideTrustpilot,
    isPayNow,
    comparisonContStyles,
}: Props) => {
    const showTwoPlans = plans.planList.length === 2
    const [activeSlide, setActiveSlide] = useState(0)
    const next = useRef(null);
    const prev = useRef(null);
    const sameProps = {
        handleSelectPlan,
        fromRegistration,
        isPayNow,
    }
    const comparisonProps = {
        ...sameProps,
        plans,
        contStyles: comparisonContStyles,
        fromRegistration,
        isPayNow,
    }
    const isShorterPlan = isPayNow && plans.period === "monthly"

    return (<div
        className={`w-full ${plansWrapperClassName || ""} relative`}
    >
        <div className='w-full lg:hidden'>
            <SwiperWrapper
                onActiveChange={(idx) => setActiveSlide(idx)}
                next={next.current}
                prev={prev.current}
            >
                {plans.planList.map((plan, i) => (
                    <SwiperSlide className="max-w-[310px]" content="fit" key={plan.planId}>
                        <div className="flex flex-col">
                            <Plan
                                {...sameProps}
                                plan={plan}
                                handleProToolUpgrade={handleProToolUpgrade}
                                wrapperClassName={`mx-auto max-lg:mb-[220px] max-sm:mb-[272px] ${isShorterPlan ? "h-[712px]" : "h-[756px]"}`}
                            />
                            <div className="lg:hidden w-full">
                                <PlansComparison
                                    {...comparisonProps}
                                    selectedPlan={plan.planId as "pro" | "pro-plus" | "enterprise"}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperWrapper>
        </div>

        <div className={`flex justify-between w-full max-lg:hidden  mx-auto ${showTwoPlans ? "max-w-[748px]" : "max-w-[1058px]"}`}
        >
            {plans.planList.map((plan, i) => (
                <Plan
                    {...sameProps}
                    plan={plan}
                    key={i}
                    handleProToolUpgrade={handleProToolUpgrade}
                    wrapperClassName=" mx-auto max-lg:mb-[200px] max-sm:mb-[272px]"
                />
            ))}
        </div>


        <div className={`w-full max-lg:absolute  ${isShorterPlan ? "max-lg:top-[720px] max-sm:top-[710px]" : "max-lg:top-[764px] max-sm:top-[754px]"}`}>
            <NextPrevSlider prev={prev} next={next} activeSlide={activeSlide} wrapperStyles="py-4 max-sm:py-6 text-white" />
            <div className={`flex justify-center py-6 lg:pt-8 max-sm:py-4 ${hideTrustpilot ? "hidden" : ""}`}>
                <Trusted trustpilotStyles='bg-white' paymentMethodsWrapper='border-2 border-solid border-white ' />
            </div>
        </div>
        <div className="w-full max-lg:hidden">
            <PlansComparison {...comparisonProps} />
        </div>
    </div>)
};

export default SlidedPlansWithComparison;
