import { FC } from "react";


interface Props {
    className?: string;
}
const HalloweenTxt: FC<Props> = ({ className }) => {
    return (
        <h6 className={`text-[28px] leading-[36px] max-sm:leading-[28px] font-medium text-center max-sm:text-xl dark:text-grey100 ${className}`}>
            <span className="font-medium text-[#FF6A00]">🎃 Halloween Madness:</span> Save Big on Yearly plans! 👻</h6>
    )
}

export default HalloweenTxt;