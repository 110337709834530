import Button from "components/Button";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PATH } from "routes/path";

interface Props {
    isYearly?: boolean;
    isCurrent?: boolean;
    isUpgrade?: boolean;
    isDowngrade?: boolean;
    id: string;
    type?: string;
    handleClick: () => void;
    isPayNow?: boolean;
    isRegFunnel?: boolean;
    isTrial?: boolean
}
const btnShadow = "!shadow-[2px_5px_30px_-4px_rgba(28,_163,_147,_0.15)]"

const StartDollarTrial: FC<Props> = ({ isYearly, isTrial, id, handleClick, isPayNow }) => {
    const btnTitle = isPayNow ? "Pay Now" : "Start 1$ Trial Now"
    const stnBtnStyles = `mb-4 w-full max-w-[246px] ${btnShadow} ${isTrial ? "" : isYearly ? "bg-darkBlue !border-darkBlue text-smoothGreen hover:!border-smoothGreen dark:text-smoothGreen" : "dark:!text-darkBlue"}`
    const stnTitleStyles = `!text-base ${isTrial ? "font-medium" : "!font-bold"} ${isYearly && !isTrial ? " group-hover:text-darkBlue" : ""}`
    const height = "h-[56px]"

    return (
        <Button title={btnTitle} titleClassName={stnTitleStyles} height={height} className={stnBtnStyles} color={isTrial ? "primaryPurple" : "smoothGreen"} handleClick={handleClick} />
    )
}
const ShopifyPlanTrialButton: FC<Props> = (props) => {
    const { isUpgrade, isCurrent, isDowngrade, isTrial } = props
    const changePlanUrl = PATH.shopify.upgrade;
    const isChangeNow = isTrial && !isCurrent
    const isCurrentTrial = isTrial && isCurrent
    const upgradeNowStyles = {
        className: "mb-4 max-w-[248px] shadow-greenShadow",
        titleClassName: "!text-base !font-bold dark:text-darkBlue group-hover:text-white ",
        height: "h-[56px]",
        color: "smoothGreen" as "smoothGreen",
        title: "Upgrade Now",
        isLink: true,
    }
    return isCurrentTrial ? <div className="w-full flex flex-col items-center">
        <Button
            {...upgradeNowStyles}
            redirect={changePlanUrl + "?isCurrentTrial=true"}
        />
        <div className="mb-4 text-center text-electricBlue text-sm ">
            Your current $1 Trial plan
        </div>
    </div> : isCurrent ?
        <div className={`mb-4 h-[37px] text-sm text-electricBlue relative after:absolute after:bottom-[0px] after:h-[2px] after:w-full after:bg-secondary after:left-[0px] px-5 after-rounded-full`}>This is your Current Plan</div> :
        isChangeNow ?
            <Button title="Change" isLink redirect={changePlanUrl} className="mb-4 max-w-[248px] dark:!border-darkBlue dark:hover:!border-primaryPurple" titleClassName="!text-base dark:text-darkBlue dark:group-hover:text-white" height="h-[56px]" /> : isDowngrade ? <Link to={changePlanUrl}
                className="flex items-center justify-center h-[56px] mb-4 font-medium cursor-pointer hover:bg-primaryPurple w-full max-w-[256px] rounded-full hover:text-white" >Downgrade</Link> :
                isUpgrade ? <Button {...upgradeNowStyles} redirect={changePlanUrl} /> :
                    <StartDollarTrial {...props} />
}

export default ShopifyPlanTrialButton;