import Button from "components/Button";
import Card from "components/Card";
import EbayMarketplaceIcon from "components/MarketplaceIcon/Ebay";
import Toaster from "components/Toast";
import { useAppDispatch } from "hooks/useAppDispatch";
import useModal from "hooks/useModal";
import PaymentHistory from "pages/ManagementPanel/lists/ManagePayments/PaymentHistory";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "routes/path";
import { handleBootIntercom } from "store/slices/intercomSlice";
import { formatDate } from "utils/formatTime";
import { getPlanFullSuffix, getPlanName } from "utils/planUtils";
import CancelationFunnel from "../CancelationFunnel";
interface Props {
  currentPlan: string;
  paymentMethod: string;
  paymentStatus: number;
  renewalDate: string;
  isCanceled?: boolean;
  isTrial?: boolean;
}

const Index = ({
  currentPlan,
  paymentMethod,
  paymentStatus,
  renewalDate,
  isCanceled,
  isTrial,
}: Props) => {
  const [cookie] = useCookies(["upCampaign"])
  const modal = useModal()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLTplan = getPlanFullSuffix(currentPlan) === "Lifetime";
  const billingURL = PATH.managementPanel[cookie.upCampaign === "true" ? "upcampaign" : "billing"]
  const isNotActive = paymentStatus === 0 || isCanceled;
  const hasTopSubscription = currentPlan === "EnterpriseLT"
  const cardStyles = `w-full h-[216px] border-2 border-solid border-secondary p-5.5`
  const btnShadow = "shadow-[0px_0px_43.012px_0px_#8FFFF2,0px_0px_24.579px_0px_#8FFFF2,0px_0px_14.337px_0px_#8FFFF2,0px_0px_7.169px_0px_#8FFFF2,0px_0px_2.048px_0px_#8FFFF2,0px_0px_1.024px_0px_#8FFFF2]"

  return (
    <>
      <div className="flex items-center gap-3 mb-5 max-xl:flex-col">
        <Card className={cardStyles} bg={`${isNotActive ? "bg-neutral2 dark:bg-grey800 dark:text-grey600 text-grey300 dark:border-grey800" : "bg-white dark:bg-black900 dark:text-grey100 dark:border-grey700"}`}>
          <div className="flex justify-between">
            <div>
              <h5 className="mb-6 text-darkBlue dark:text-grey100">My Subscription</h5>
              <h5 className={`text-[28px] leading-[36px] mb-6 ${!isNotActive ? "text-darkBlue dark:text-grey100" : "text-grey300 dark:text-grey600 "}`}>
                <span className="font-bold uppercase">{getPlanName(currentPlan)} </span>
                <span className="font-medium lowercase">/ {getPlanFullSuffix(currentPlan)}</span>
              </h5>
            </div>
            <EbayMarketplaceIcon isDisabled={isNotActive} />
          </div>
          <div className="flex justify-between items-end">
            <div className="flex flex-col font-bold text-sm">
              <p className="">Renewal date: <span className="font-normal">{formatDate(renewalDate, "DD MMM YYYY")}</span></p>
              <p className="">Payment method: <span className="font-normal capitalize">{paymentMethod.replace("_", " ").toLowerCase()}</span></p>
              <p className="">Subscription status: <span className={`font-normal ${isNotActive ? "text-errorRed" : "text-smoothGreen"}`}>{isNotActive ? "Canceled" : "Active"}</span></p>
            </div>
            {isNotActive ? "" : (
              <div className="flex justify-end gap-[26px]">
                <button className={`dark:hover:text-grey100 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 h-9 text-neutral3 ${isLTplan ? "min-w-[140px]" : "min-w-[80px]"}`}
                  onClick={() => isLTplan ? dispatch(handleBootIntercom(true)) : modal.open()} >{isLTplan ? "Talk To Support" : "Cancel"}</button>
                <Link to={billingURL}
                  className="dark:text-lilac400 hover:bg-primaryPurple rounded-full text-sm hover:text-white dark:hover:bg-purple500 dark:hover:text-grey100 min-w-[182px] h-9 flex items-center justify-center" >
                  Manage Subscription
                </Link>
              </div>
            )}
          </div>
        </Card>
        {hasTopSubscription ? <div className="w-full max-xl:hidden" /> : (<Card className={`${cardStyles} dark:border-neutral3 bg-[linear-gradient(259deg,_#9E7EF6_0.51%,_#6831FF_40.07%,_#6831FF_69.27%,_#BEA6FF_100%)] flex flex-col`}>
          <div className="text-white bg-darkBlue w-fit text-xl py-2.5 px-[11.5px] rounded-10 font-medium my-[38px]">
            Upgrade to Yearly plan and save $500
          </div>
          <Button
            title={isTrial
              ? "Change Now"
              : paymentStatus === 0
                ? "Pay Now"
                : "Upgrade Now"}
            className={`bg-white max-w-[250px] border-smoothGreen hover:bg-smoothGreen hover:border-smoothGreen dark:border-paradiseBlue dark:hover:!bg-paradiseBlue 
                        dark:hover:!border-paradiseBlue ${btnShadow}`}
            titleClassName="dark:text-darkBlue group-hover:text-white !text-base"
            isLink
            redirect={billingURL}
            height="h-12" />
        </Card>)}
      </div>

      {paymentStatus === 0 || isCanceled ? "" :
        <CancelationFunnel helpers={modal} onUpgrade={() => navigate(billingURL)} />
      }
      <Toaster />
      <PaymentHistory />
    </>
  )
};

export default Index;
