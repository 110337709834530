import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { ReactComponent as Ebay } from "assets/icons/ebay.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { PlanList, TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import {
  getPlanFullSuffix,
  getPlanSuffix,
  isCurrentPlan,
  isDowngrade,
  isUpgrade
} from "utils/planUtils";
import PlanFeatures from "./PlanFeatures/PlanFeatures";


interface Props {
  plan: PlanList;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  handleProToolUpgrade?: () => void;
  fromRegistration?: boolean;
  isPayNow?: boolean;
  wrapperClassName?: string;
}


const Plan = ({
  plan,
  handleSelectPlan,
  handleProToolUpgrade,
  fromRegistration = false,
  isPayNow,
  wrapperClassName,
}: Props) => {

  const user = useDecodeToken();
  const currentPlan = user.package_name || "";
  const isProTools = handleProToolUpgrade !== undefined
  const showUpgrade = isUpgrade(currentPlan || "", plan.type) || isProTools;
  const showSkipTrial = fromRegistration
  const showCurrent = isCurrentPlan(currentPlan || "", plan.type);
  const showDowngrade = isDowngrade(currentPlan || "", plan.type) && !isProTools;
  const isTrial = /^true$/i.test(user.active_trail);
  const showChange = isTrial && !isProTools && !showCurrent;
  const isCurrentTrial = isTrial && showCurrent
  const showPayNow = isPayNow && !fromRegistration;
  const isStandardOrProPlus = plan.type.toLowerCase().includes("standard") ||
    plan.type.toLowerCase().includes("proplus")
  const planPeriod = getPlanFullSuffix(plan.type);
  const isMonthly = planPeriod === "Monthly"
  const isYearly = planPeriod === "Yearly";
  const isLifetime = planPeriod === "Lifetime"
  const hideDiscounted = !plan.isDiscount || ((!showUpgrade || showCurrent) && isMonthly && !isProTools && !isTrial)
  const onBtnClick = (skip?: boolean) => {
    handleSelectPlan?.({
      type: plan.type,
      planName: plan.planName,
      planPrice: plan.planPrice,
      skip: skip || false
    });
    handleProToolUpgrade && handleProToolUpgrade();
  }

  const middlePlanBtnStyles = "!bg-darkBlue !text-smoothGreen hover:!bg-smoothGreen hover:!border-smoothGreen dark:!border-darkBlue dark:hover:!border-smoothGreen hover:!text-darkBlue dark:border-darkBlue"
  const btnStyles = "my-4 max-w-[246px] w-full flex mx-auto"

  const sameBtnProps = {
    className: `${btnStyles} shadow-greenShadow ${isStandardOrProPlus || isProTools ? middlePlanBtnStyles : " border-smoothGreen dark:border-smoothGreen dark:!text-darkBlue dark:hover:!text-white"}`,
    titleClassName: "!text-base !font-bold",
    height: "h-[56px]",
    handleClick: () => onBtnClick()
  }

  return (
    <div
      className={`w-[310px] bg-white flex flex-col justify-between rounded-[16px] 
      ${(plan.highlight || isProTools) ? "!border-[2px] !border-smoothGreen shadow-greenShadow" : fromRegistration ? "!border-[2px] border-secondary" : "border-white"}  ${wrapperClassName || ""}`}
    >
      <div className="p-3.5">
        <div>
          <div className="flex justify-between items-center mb-1">
            <h2 className="text-[28px] font-bold uppercase leading-[36px]">
              {plan.planName}
            </h2>
            <div className="flex justify-center items-center w-[62px] h-8 bg-neutral2 rounded-full">
              <Ebay className="w-[41px] h-[21.75px]" />
            </div>
          </div>
          <p className={`text-xs  h-12 mb-1`}>{plan.planDescription}</p>

          {hideDiscounted ? <div className="text-2xl font-bold h-[32px]">
            {formatCurrency(plan.planPrice)}
            <span className="font-normal">
              {getPlanSuffix(plan.type)}
            </span>
          </div> :
            <>
              <div className="mb-1">
                <span className="relative">
                  {formatCurrency(plan.planPrice)}
                  <span className="absolute w-[46px] h-[2px] bg-errorRed rounded-full rotate-[-17.17deg] left-[0px] top-2 " />
                </span>
                <span className="font-normal">{getPlanSuffix(plan.type)}</span>
              </div>

              <div className="flex gap-4 items-center">
                <div className="text-2xl font-bold h-[32px] ">
                  {formatCurrency(plan.planDiscountPrice)}
                  <span className="font-normal">
                    {getPlanSuffix(plan.type)}
                  </span>
                </div>
                {isYearly ?
                  <div className="flex items-center">
                    <span className="text-[#FF6A00] text-base font-medium">Save ${plan.planPrice - plan.planDiscountPrice}!</span>
                    <div className="flex w-[30px] justify-center">
                      <Checkmark className="fill-smoothGreen" />
                    </div>
                  </div>
                  : (
                    <div className={`text-base ${isMonthly ? "h-11" : ""}`}>
                      <span className={`text-[#24921A] font-bold block leading-[20px]`}>{Math.round(100 - plan.planDiscountPrice / plan.planPrice * 100)}% off</span>
                      {isMonthly ? <span className="font-medium leading-[20px] text-sm">for 1st month</span> : ""}
                    </div>
                  )}
              </div>
            </>
          }
        </div>
        {isCurrentTrial ?
          <div className="mt-4">
            <Button
              title="Upgrade Now"
              {...sameBtnProps}
            />
            <div className="mb-4 text-center text-electricBlue text-sm ">
              Your current $1 Trial plan
            </div>
          </div>
          : showChange ? (
            <Button
              title="Change"
              {...sameBtnProps}
            />
          ) : showPayNow ? <Button
            title="Pay Now"
            {...sameBtnProps}
          /> : showDowngrade ? (
            <Button
              className={`!border-transparent dark:hover:bg-primaryPurple normal-case max-w-[246px] flex w-full mx-auto  my-4`}
              titleClassName={"font-medium !text-base dark:text-darkBlue group-hover:text-white"}
              height="h-[56px]"
              title="Downgrade"
              handleClick={() => onBtnClick()}
            />
          ) : showCurrent ? (
            <div className="max-w-[210px] w-full mx-auto mt-7 mb-[29px] relative">
              <div className="mb-[15px] text-center text-electricBlue text-sm font-medium">
                This is your Current Plan
              </div>
              <div className="h-[2px] w-full  after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:rounded-full after:bg-secondary " />
            </div>
          ) : showUpgrade ? (
            <Button
              title={fromRegistration
                ? `Start $1 Trial ${plan.skipAmout === 0 ? "" : "Now"}`
                : "Upgrade now"}
              {...sameBtnProps}

            />
          ) : null}
        {showSkipTrial && <div className="flex justify-center items-center mb-3.5">
          <button
            className="cursor-pointer text-electricBlue hover:text-primaryPurple underline text-xs"
            onClick={() => onBtnClick(true)}
          >
            Skip Trial {plan.skipAmout === 0 ? "Now!" : `and Save ${formatCurrency(plan.skipAmout || 0)}`}
          </button>
        </div>
        }
        {isLifetime && (
          <div className="text-xs text-center mb-1">Pay once, use forever</div>
        )}

        <PlanFeatures planType={plan.type} isProTools={isProTools} />
      </div>
      <div className="min-h-[42px] flex justify-center font-medium text-darkBlue rounded-b-[15px] text-xs">
        <span className="bg-neutral2 text-[10px] rounded-[5px] w-full py-1.5 max-w-[278px] max-xl:max-w-[240px] h-fit text-center">
          VIP Community + Training + Live Chat
        </span>
      </div>
    </div>
  )
};

export default Plan;
