import { useEbaySubCountQuery } from "store/api/quickSettingsApi";
import EbayPlanPeriodSelect from "./EbayPeriodSelect";
import PlanPeriodRadio from "./PlanPeriodRadio";

interface Props {
  activePlan: string;
  handlePlanChange: (type: string) => void;
  shortSwitchStyles?: string;
  longSwitchStyles?: string;
  showTwoPlans?: boolean;
  showLifetime?: boolean;
}
const switches = ["monthly", "yearly", "lifetime"];

const PlanSwitch = ({ activePlan, handlePlanChange, shortSwitchStyles, longSwitchStyles, showLifetime, showTwoPlans }: Props) => {
  const { data: ebaySubCount } = useEbaySubCountQuery();

  const hasEbayPlan = ebaySubCount?.result !== 0;
  return hasEbayPlan || showLifetime ? (
    <div className={`rounded-full flex max-sm:flex-wrap max-sm:justify-start row-reverse justify-end gap-2 w-full mx-auto ${longSwitchStyles}`}>
      {switches.map((itm, i) => (
        <PlanPeriodRadio key={i} period={itm} active={activePlan} onPeriodSelect={handlePlanChange} showLabel={itm === "yearly"} />
      ))}
    </div>
  ) : <EbayPlanPeriodSelect selectedPeriod={activePlan} handleClick={handlePlanChange} wrapperStyles={shortSwitchStyles} showTwoPlans={showTwoPlans} />
};

export default PlanSwitch;
