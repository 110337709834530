import BillingBackButton from "components/Plans/BackButton";
import HalloweenTxt from "components/Plans/HalloweenTxt";
import useDecodeToken from "hooks/useDecodeToken";
import BillingHeader from "pages/Shopify/Billing/components/BillingHeader";
import SelectMarketplace from "pages/Shopify/Billing/components/SelectMarketplace";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ShopifyPlans from "./components/ShopifyPlans";


const ShopifyLocked = () => {
    const [pageType, setPageType] = useState<"couponUsed" | "alreadyTrial" | null>(null);
    const isCouponUsed = pageType === "couponUsed"
    const [searchParams] = useSearchParams();
    const user = useDecodeToken()
    const shopifyAlreadyTrial = user.shopify_already_trial === "1";

    useEffect(() => {
        if (searchParams.get("CouponUsed")) {
            setPageType("couponUsed");
        }

        if (searchParams.get("AlreadyTrial") || shopifyAlreadyTrial) {
            setPageType("alreadyTrial");
        }

    }, [searchParams, shopifyAlreadyTrial])

    return (
        <div>
            <BillingHeader />
            <BillingBackButton>
                <div className="flex flex-col gap-4 mb-[27.5px]">
                    {pageType !== null ? <div className="px-[22px]">
                        <h1 className="text-center text-[28px] max-lg:text-[24px] max-sm:text-[18px] font-bold text-errorRed mb-4 max-lg:mb-[30px] max-sm:mb-3">
                            {isCouponUsed ? <span>You have already used this Coupon. <br className='max-lg:hidden' /> As this exclusive Coupon has been used, the full subscription price will be applied.</span> :
                                "You already used a Trial before, please pick a Plan to continue with a Subscription!"} </h1>
                        <h2 className="text-center font-meium text-[24px] max-lg:text-[20px] max-sm:text-[16px]">{isCouponUsed ?
                            "Please select a plan to start your subscription." :
                            <span>In order to continue using ZIK Analytics, please complete payment process below.<br className='max-lg:hidden' /> Since you have used a free trial before, the full plan subscription price will be changed.</span>}
                        </h2>
                    </div> : <h4 className="text-center text-lg font-medium dark:text-grey100">Pick your plan</h4>}
                    <h5 className="text-center font-bold dark:text-grey100">Select your Marketplace</h5>
                    <SelectMarketplace marketplace={"shopify"} hideEbay onMarketplaceChange={() => { }} />
                    <HalloweenTxt />
                </div>
            </BillingBackButton>

            <ShopifyPlans isPayNow={pageType !== null} />
        </div>
    )
}

export default ShopifyLocked;