import { ReactComponent as ItemFinder } from "assets/icons/boxes/box.svg";
import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";

const descs = {
  pro: "Provides professional research tools for making data-driven business decisions",
  proPlus:
    "Provides big data and automations to increase market share to the next level",
  enterprise:
    "Provides complete automation and maximum data to capture all possible opportunities",
};
const monthlyTwoPlans = [
  {
    type: "StarterM",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: true,
    planPrice: 29.99,
    planDiscountPrice: 14.99,
    highlight: false,
    skipAmout: 0,
  },
  {
    type: "StandardM",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: true,
    planPrice: 59.99,
    planDiscountPrice: 29.99,
    highlight: true,
    skipAmout: 0,
  },
];
const yearlyTwoPlans = [
  {
    type: "StarterY",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: true,
    planPrice: 299,
    planDiscountPrice: 199,
    upcampaignPrice: 179.93,
    highlight: false,
    skipAmout: 15,
  },
  {
    type: "StandardY",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: true,
    planPrice: 599,
    planDiscountPrice: 299,
    upcampaignPrice: 299.94,
    highlight: true,
    skipAmout: 25,
  },
];
const lifetimeTwoPlans = [
  {
    type: "ProLT",
    planName: "Pro",
    planId: "pro",
    planDescription: descs.pro,
    isDiscount: true,
    planPrice: 1994,
    planDiscountPrice: 997,
    highlight: false,
  },
  {
    type: "ProPlusLT",
    planName: "Pro+",
    planId: "pro-plus",
    planDescription: descs.proPlus,
    isDiscount: true,
    planPrice: 3594,
    planDiscountPrice: 1797,
    highlight: true,
  },
];

export const monthlyTwo = {
  period: "monthly",
  planList: monthlyTwoPlans,
};
export const yearlyTwo = {
  period: "yearly",
  planList: yearlyTwoPlans,
};
export const lifetimeTwo = {
  period: "lifetime",
  planList: lifetimeTwoPlans,
};

export const monthly = {
  period: "monthly",
  planList: [
    ...monthlyTwoPlans,
    {
      type: "EnterpriseM",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: true,
      planPrice: 89.99,
      planDiscountPrice: 44.99,
      highlight: false,
      skipAmout: 0,
    },
  ],
};

export const yearly = {
  period: "yearly",
  planList: [
    ...yearlyTwoPlans,
    {
      type: "EnterpriseY",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: true,
      planPrice: 899,
      planDiscountPrice: 399,
      upcampaignPrice: 359.95,
      highlight: false,
      skipAmout: 25,
    },
  ],
};

export const lifetime = {
  period: "lifetime",
  planList: [
    ...lifetimeTwoPlans,
    {
      type: "EnterpriseLT",
      planName: "Enterprise",
      planId: "enterprise",
      planDescription: descs.enterprise,
      isDiscount: true,
      planPrice: 5994,
      planDiscountPrice: 2997,
      highlight: false,
    },
  ],
};

export const proToolsProPlus = {
  period: "",
  planList: [
    monthlyTwo.planList[1],
    yearlyTwo.planList[1],
    lifetimeTwo.planList[1],
  ],
};

export const proToolsEnterprise = {
  period: "",
  planList: [monthly.planList[2], yearly.planList[2], lifetime.planList[2]],
};

export const plansComparison = [
  {
    Icon: Logo,
    icon: "dashboard",
    title: "Market Insights",
    description:
      "Find the best products <br className='max-md:hidden' /> to maximize sales.",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 14,
    height: 14,
  },
  {
    Icon: ProductResearch,
    icon: "product",
    title: "Product Research",
    description:
      "Find the hottest products and <br className='max-md:hidden' /> increase your store's profits",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 12,
    height: 12,
  },
  {
    Icon: CompetitorResearch,
    icon: "competitor",
    title: "Competitor Research",
    description:
      "Get current intel on competitors <br className='max-md:hidden' /> products and strategies.",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 13,
    height: 12,
  },
  {
    Icon: CategoryResearch,
    icon: "category",
    title: "Category Research",
    description:
      "See top selling items and <br className='max-md:hidden' /> sellers in any eBay category.",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 12,
    height: 11,
  },
  {
    Icon: TitleBuilder,
    icon: "titleBuilder",
    title: "Title Builder",
    description:
      "Know and use all the keywords <br className='max-md:hidden' /> currently driving traffic + sales.",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 12,
    height: 11,
  },
  {
    Icon: ItemFinder,
    icon: "itemFinder",
    title: "Item Finder",
    description:
      "Find and cross-compare eBay <br className='max-md:hidden' /> items with products on AliExpress, <br className='max-md:hidden' /> Alibaba, Amazon and Walmart.",
    pro: true,
    proPlus: true,
    enterprise: true,
    width: 14,
    height: 13,
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "500 Best Selling eBay Items",
    description:
      "Find and cross-compare eBay <br className='max-md:hidden' /> items with products on AliExpress, <br className='max-md:hidden' /> Alibaba, Amazon and Walmart.",
    pro: false,
    proPlus: true,
    enterprise: true,
    width: 13.84,
    height: 12.19,
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Bulk Scanner",
    description:
      "Instantly find thousands of <br className='max-md:hidden' /> Amazon and Walmart dropship items.",
    pro: false,
    proPlus: true,
    enterprise: true,
    width: 13.84,
    height: 12.19,
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Ali Growth Scanner",
    description:
      "Easily mine data on trends <br className='max-md:hidden' /> coming from AliExpress.",
    pro: false,
    proPlus: true,
    enterprise: true,
    width: 13.84,
    height: 12.19,
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Autopilot",
    description:
      "Automatically find dropshipping items <br className='max-md:hidden' /> from AliExpress, Amazon and Walmart.",
    pro: false,
    proPlus: "limited" as "limited",
    enterprise: true,
    width: 13.84,
    height: 12.19,
  },
  {
    Icon: ZikPro,
    icon: "proTools",
    title: "Turboscanner",
    description:
      "Automatically discover the best selling <br className='max-md:hidden' /> items from Amazon and Walmart.",
    pro: false,

    proPlus: "limited" as "limited",
    enterprise: true,
    width: 13.84,
    height: 12.19,
  },
];
