import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import Button from "components/Button";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { PlanList, PlansType, TSelectedPlan } from "types/plans";
import { formatCurrency } from "utils/formatCurrency";
import {
  getPlanSuffix, isDowngrade,
  isUpgrade
} from "utils/planUtils";

interface Props {
  plans: PlansType;
  showCheckout?: () => void;
  handleSelectPlan?: (newVal: TSelectedPlan) => void;
  fromRegistration?: boolean;
  selectedPlan?: "pro" | "pro-plus" | "enterprise";
  isPayNow?: boolean;
}
interface CurrentPlanProps {
  plan: PlanList;
  isTrial?: boolean;
}
const CurrentPlan: FC<CurrentPlanProps> = ({ plan, isTrial }) => {
  return (
    <div className="relative">
      <div className={`bg-secondary text-center ${isTrial ? "px-[30px]" : "px-[61px]"} pt-[28px] pb-[31px] rounded-b-[15px] text-sm font-medium w-[calc(100%-2px)]`}>
        {isTrial ? "This is your current $1 Trial plan" : "This is your Current Plan"}

      </div>
      {plan.type.includes("Enterprise") ? (
        <div className="absolute bg-secondary  w-full h-[50px] bottom-[100%] left-[-2px]" />
      ) : ""}
    </div>
  )

}

const PlanComparisonFooter = ({
  plans,
  selectedPlan,
  handleSelectPlan,
  fromRegistration = false,
  isPayNow,
}: Props) => {
  const showTwoPlans = plans.planList.length === 2
  const user = useDecodeToken();
  const isTrial = /^true$/i.test(user.active_trail);
  const currentPlan = user.package_name || "";
  const isMonthly = plans.period === "monthly"
  const showDarkBlue = (plan: string) => (user.package_name === "StandardM" && plan === "EnterpriseM") || (user.package_name === "StandardY" && plan === "EnterpriseY") || (user.package_name === "ProPlusLT" && plan === "EnterpriseLT")
  return (
    <div className="max-w-[1220px] w-full">
      <div className={`grid max-lg:grid-cols-1 ${showTwoPlans ? "grid-cols-3" : "grid-cols-4"}`}>
        <div />
        {plans.planList.map((plan, i) =>
          isDowngrade(currentPlan || "", plan.type) ? <div className="h-10" key={i}></div> : plan.type === currentPlan ? (
            <CurrentPlan plan={plan} isTrial={isTrial} key={i} />
          ) : (
            <div key={i} className={`${plan.planId !== selectedPlan ? "max-lg:hidden" : "max-lg:border-2 max-lg:border-t-0 max-lg:border-smoothGreen max-lg:rounded-b-[15px] max-lg:max-w-[291px] max-lg:w-full max-lg:mx-auto max-lg:relative max-lg:-top-[4px] max-lg:pt-1"}`}>
              <div
                className={`${plan.highlight || showDarkBlue(plan.type) ? "bg-darkBlue text-white" : "bg-secondary text-darkBlue"} rounded-b-[13px] py-2 lg:w-[calc(100%-2px)] lg:mx-auto px-2 relative ${(plan.type.includes("Standard") ||
                  plan.type.includes("ProPlus")) &&
                  "border-[2px] border-t-0 border-smoothGreen"
                  } ${plan.planId === selectedPlan ? " max-lg:border-none" : ""}`}
              >
                <div className="flex justify-between items-center">
                  <div className={`text-center font-bold text-xl uppercase`}>
                    {plan.planName}
                  </div>
                  {plan.highlight && (
                    <span className={`max-w-[97px] w-full capitalize text-xs rounded-[4px] font-medium bg-electricBlue text-center py-1.5`}>
                      most popular
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <div className={"font-normal text-lg flex justify-center items-center"}>
                    {plans.period === "yearly" ? <span>
                      <span className="font-bold">{formatCurrency(plan.planDiscountPrice)}</span></span> : <span>{formatCurrency((isMonthly && fromRegistration) || plans.period === "lifetime" ? plan.planDiscountPrice : plan.planPrice)}</span>}
                    <span>{getPlanSuffix(plan.type)}</span>
                  </div>
                  {plans.period === "yearly" ? (
                    <div className={`flex items-center font-medium ${plan.highlight ? "" : "relative bottom-[15px]"}`}>
                      <span className="text-[#FF6A00] text-sm">Save ${plan.planDiscountPrice && (plan.planPrice - plan.planDiscountPrice)}!</span>
                      <div className="flex w-[22px] justify-center">
                        <Checkmark className="fill-smoothGreen w-[11px] h-[8px]" />
                      </div>
                    </div>
                  ) : ""}
                </div>
                {isUpgrade(currentPlan || "", plan.type) ? (
                  <div>
                    <Button
                      className={` !bg-white hover:!bg-smoothGreen text-darkBlue font-bold max-w-[206px] mt-4 flex w-full mx-auto`}
                      height="h-11"
                      color="smoothGreen"
                      titleClassName={`!font-medium group-hover:text-white !text-base dark:text-darkBlue`}
                      title={
                        fromRegistration
                          ? "Start $1 Trial Now"
                          : isTrial
                            ? "Change"
                            : isPayNow
                              ? "Pay now"
                              : "Upgrade now"
                      }
                      handleClick={() => {
                        handleSelectPlan?.({
                          type: plan.type,
                          planName: plan.planName,
                          planPrice: plan.planPrice,
                          skip: false
                        });
                      }}
                    />
                    {fromRegistration && <div className="flex justify-center items-center ">
                      <button
                        className={`text-xs cursor-pointer hover:text-primaryPurple dark:hover:text-purple500 underline mt-2 leading-[16px] ${plan.highlight ? "text-smoothGreen" : "text-electricBlue"}`}
                        onClick={() => {
                          handleSelectPlan?.({
                            type: plan.type,
                            planName: plan.planName,
                            planPrice: plan.planPrice,
                            skip: true
                          });
                        }}
                      >
                        Skip Trial {plan.skipAmout === 0 ? "Now!" : `and Save ${formatCurrency(plan.skipAmout || 0)}`}
                      </button>
                    </div>}
                  </div>
                ) : null}
                <div className={`absolute w-full h-[50px] bottom-[100%] left-0 ${showTwoPlans ? "bg-darkBlue" : "bg-secondary"}`} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default PlanComparisonFooter;
