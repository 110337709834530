import { ReactComponent as ArrowRightExtendedBold } from "assets/icons/arrow-right-extended-bold.svg";
import Button from "components/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import useDecodeToken from "hooks/useDecodeToken";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "routes/path";
import { useLazyShopifyUpgradeQuery } from "store/api/managePaymentApi";
import { setUserAccessToken } from "store/slices/userSlice";
import { SetAccessToken } from "utils/localStorage";
interface Props {
    selectedPlanType: string;
    coupon: string;
}
const BillingDetails: FC<Props> = ({ selectedPlanType, coupon }) => {
    const planCardStyles = "rounded-[15px] flex flex-col justify-center pl-6 w-full gap-2"
    const user = useDecodeToken();
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const isCurrentTrial = search.includes("isCurrentTrial=true");
    const isYearly = user.shopify_package_name === "ShopProY";
    const isTrial = /^true$/i.test(user.shopify_active_trail);
    const navigate = useNavigate()
    const isDowngrading = isYearly;
    const [makeUpgrade, { isFetching }] = useLazyShopifyUpgradeQuery();

    const handleUpgrade = () => {
        makeUpgrade({
            type: selectedPlanType,
            couponName: coupon,
        })
            .unwrap()
            .then((data) => {
                if (data.result.token != null && data.result.token != "") {
                    SetAccessToken(data.result.token);
                    dispatch(setUserAccessToken(data.result.token));
                }
                if (data.result.trial) {
                    navigate(PATH.purchaseComplete.changed);
                } else if (data.result.downgrade) {
                    navigate(PATH.purchaseComplete.shopifyDowngrade);
                } else if (data.result.message === "redirect" && data.result.additionalInformation !== null) {
                    window.location.replace(data.result.additionalInformation!)
                }
                else {
                    navigate(PATH.purchaseComplete.shopifyUpgrade);
                }
            })
            .catch((error) => { toast.error(error.data.message) });
    };


    return (
        <div className="flex flex-col gap-6">
            <div className="flex items-center">
                <div className={`h-[100px] ${isDowngrading ? "bg-grey100 text-grey300 dark:bg-grey700" : "bg-neutral2 dark:bg-grey100 dark:text-deepSpace900"} ${planCardStyles}`}>
                    <h6 className="text-xl font-bold">Monthly</h6>
                    <p className="text-sm font-medium">{isDowngrading ? "Downgrading to" : "Current Plan"}</p>
                </div>
                <div className="flex items-center justify-center min-w-[46px] h-[46px]">
                    <ArrowRightExtendedBold className={`w-[26.83px] h-[23px] ${isDowngrading ? "rotate-180 fill-grey300 dark:fill-grey700" : "fill-smoothGreen dark:fill-paradisBlue"}`} />
                </div>
                <div className={`h-[140px] bg-darkBlue text-white dark:bg-paradiseBlue dark:text-deepSpace900 ${planCardStyles}`}>
                    <h6 className="text-[36px] font-bold">{isCurrentTrial && !isYearly ? "Monthly" : "Yearly"}</h6>
                    <p className="text-base font-medium">{isDowngrading ? "Current Plan" : "Upgrading to"}</p>
                </div>
            </div>
            {((isDowngrading || isTrial) && !isCurrentTrial) ? <button
                className={`w-full py-4 hover:bg-primaryPurple dark:text-lilac400 rounded-full dark:hover:bg-purple500 dark:text-sm font-bold dark:font-medium hover:text-white
                 ${isTrial ? "border-2 border-darkBlue dark:border-lilac400 dark:hover:border-purple500 hover:border-primaryPurple" : ""}`}
                onClick={handleUpgrade}>{isTrial ? "Change Now" : "Downgrade"}</button> : (
                <Button
                    title="Upgrade Now"
                    color="smoothGreen"
                    className="bg-smoothGreen dark:bg-paradisBlue dark:bg-deepSpace900 dark:hover:!bg-purple500 dark:!border-lilac400 hover:!bg-primaryPurple hover:border-primaryPurple dark:hover:!border-purple500"
                    titleClassName="text-white !text-base !font-bold dark:text-lilac400"
                    height="h-[56px]"
                    loading={isFetching}
                    handleClick={handleUpgrade}
                />
            )}
        </div>

    )
}

export default BillingDetails