import EbayPlanPeriodSelect from "components/Plans/EbayPeriodSelect";
import HalloweenTxt from "components/Plans/HalloweenTxt";
import usePlans from "hooks/usePlans";
import SelectMarketplace from "pages/Shopify/Billing/components/SelectMarketplace";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PATH } from "routes/path";
import { TSelectedPlan } from "types/plans";
import { gtmOnboardingPlansScreenView } from "utils/googleTagManager";
import PlansContentA from "../components/PlansContentA";
import PlansContentB from "../components/PlansContentB";

interface Props {
  selectedPlan: TSelectedPlan;
  mainChannel: number | null;
  handleSelectPlan: (newVal: TSelectedPlan) => void;
}

const SelectPlan = ({ handleSelectPlan, selectedPlan, mainChannel }: Props) => {
  const { pathname, search } = useLocation();
  const isShopifyActive = search.includes("marketplace=shopify")
  const [selectedMarketplace, setSelectedMarketplace] = useState<"ebay" | "shopify">(isShopifyActive || mainChannel === 3 ? "shopify" : "ebay")
  const { plans, handlePlanChange } = usePlans("monthly", false, true);
  const onMarketplaceChange = (tab: "ebay" | "shopify") => setSelectedMarketplace(tab);
  const isTestB = pathname.includes(PATH.regFunnel.b);
  const isEbay = selectedMarketplace === "ebay"

  useEffect(() => {
    if (selectedPlan.type !== "") {
      if (selectedPlan.type.includes("ShopPro")) {
        setSelectedMarketplace("shopify")
      } else {
        setSelectedMarketplace("ebay")
      }
    }

  }, [selectedPlan])

  useEffect(() => {
    gtmOnboardingPlansScreenView(); // google tag manager event that fires when user land on this page
  }, [])


  const contentProps = {
    plans,
    isEbay,
    handleSelectPlan
  }
  return (
    <div className={`flex flex-col justify-center items-center max-lg:pt-0 ${isTestB ? "max-lg:pt-4" : "pt-2"}`}>
      <h4 className={`py-4 font-bold max-sm:pt-0 ${isTestB ? "max-sm:pb-4" : "max-sm:pb-6"}`}>Select your Marketplace</h4>
      <SelectMarketplace marketplace={selectedMarketplace} onMarketplaceChange={onMarketplaceChange} />
      <HalloweenTxt className="mt-4 max-sm:mt-2" />
      {isEbay ?
        <EbayPlanPeriodSelect showTwoPlans selectedPeriod={plans.period} handleClick={(tab: string) => handlePlanChange(tab)} />
        : ""}
      {isTestB ? (
        <PlansContentB {...contentProps} />
      ) : (
        <PlansContentA {...contentProps} />
      )}
    </div >

  );

};
export default SelectPlan;
